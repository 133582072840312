import React from 'react';

import Home from './pages/Home';

import './fonts/fonts.css';
import './styles/global.css';

function App() {
  return (
    <Home />
  );
}

export default App;
