import React from 'react';
import { ScrollTo } from "react-scroll-to";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import './styles.css';

function Home() {

let src = 'https://player.vimeo.com/video/480521486?loop=1';

function play(){  
  let myVid = document.getElementById("video-iframe");
  myVid.src = 'https://player.vimeo.com/video/480521486?loop=1';
}

 return(
  <>
    <Header />
    <main>
      <section id="home">
        <div className="video" onClick={play}>
          <iframe id="video-iframe"  src={src} width="100%" frameBorder="0" title="home" allow="autoplay; fullscreen" allowFullScreen=""></iframe>
            <div className="content">
              <div className="container">
                <ScrollTo onClick={play}>
                  {({ scroll }) => (
                    <button onClick={() => scroll({ x: 20, y: 2000 })}>Quero um médico para chamar de meu</button>
                  )}
                </ScrollTo>
              </div>
            </div>
        </div>
        <div className="bg"></div>
      </section>
      <section id="form-content">
        <div className="container">
          <p>Na Amparo Saúde você é atendido pelo seu médico de família, que avalia você e a sua família 
          como um todo para entender suas necessidades e <strong>cuidar de vocês por completo.</strong></p>
          <p>Para saber mais e poder aproveitar os serviços da <strong>Amparo Saúde, </strong>
          preencha o formulário abaixo e aguarde o contato dos nossos representantes. </p>
          <div className="form">
            <form>
              <div>
                <input type="text" placeholder="Nome" />
              </div>
              <div className="half">
                <input type="text" placeholder="Cidade" />
                <input type="text" placeholder="Estado" />
              </div>
              <div>
                <input type="email" placeholder="E-mail" />
              </div>
              <div>
                <input type="text" placeholder="Telefone" />
              </div>
              <div className="half">
                <select name="convenio" id="convenio">
                  <option value="Convenio">Convênio</option>
                  <option value="Careplus">Careplus</option>
                  <option value="Fundação Assefaz">Fundação Assefaz</option>
                  <option value="GEAP Saúde">GEAP Saúde</option>
                  <option value="Omint">Omint</option>
                  <option value="Sulamérica">Sulamérica</option>
                  <option value="Unimed Seguros">Unimed Seguros</option>
                  <option value="Não tenho convênio">Não tenho convênio</option>
                </select>
                <input type="text" placeholder="Outro" />
              </div>
              <button>Enviar</button>
            </form>
          </div>
        </div>
      </section>
    </main>
    <Footer />
  </>
 );
}

export default Home;