import React from 'react';

import logo_footer from '../../assets/logo_footer.png';
import logo1 from '../../assets/logo-assefaz.png';
import logo2 from '../../assets/logo-geap.png';
import logo3 from '../../assets/logos-amil.png';
import logo4 from '../../assets/logos-care-plus.png';
import logo5 from '../../assets/logos-omint.png';
import logo6 from '../../assets/logos-sulamerica.png';
import logo7 from '../../assets/logos-unimed.png';

import './styles.css';

function Footer(){
  return(
    <footer>
      <div className="container">
        <div className="logo-footer">
          <img src={logo_footer} alt="Amparo"/>
        </div>
        <div className="footer-content">
          <div><img src={logo1} alt="Assefaz"/></div>
          <div><img src={logo2} alt="Geap"/></div>
          <div><img src={logo3} alt="Amil"/></div>
          <div><img src={logo4} alt="Care Plus"/></div>
          <div><img src={logo5} alt="Omint"/></div>
          <div><img src={logo6} alt="Sulamerica"/></div>
          <div><img src={logo7} alt="Unimed"/></div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;